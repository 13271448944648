import React, { Component } from 'react'

class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404 - Page Not Found</h1>
                <p>Please return to the previous page.</p>
            </div>
        )
    }
}

export default NotFound